import { PropsWithChildren } from 'react';
import { Grid, Stack } from '@mui/material';

import type { MarketingOrg, SumPlanState } from '@/api/domains/promo-contracts.types';
import { DorimPromoPlanChart } from '@/components/DorimPromoBonusChart';

import { MarketingOrgCardInfoBlock } from './MarketingOrgCardInfoBlock';

export type MarketingOrgCardProps = PropsWithChildren & {
  marketingOrg: MarketingOrg;
  sumPlanState: SumPlanState;
};

export const MarketingOrgCard = (props: MarketingOrgCardProps) => {
  const { marketingOrg, sumPlanState, children } = props;

  return (
    <Grid container item xs={12} spacing={2} py={3}>
      <Grid item xs={4}>
        <MarketingOrgCardInfoBlock
          marketingOrgName={marketingOrg.marketing_org?.full_name}
          sumPlanState={sumPlanState}
        />
      </Grid>

      <Grid item xs={8}>
        <Stack direction="row" spacing={2}>
          <DorimPromoPlanChart
            totalPurchased={marketingOrg.order_sum}
            minPlan={marketingOrg.minimum_plan}
            maxPlan={marketingOrg.maximum_plan}
          />

          {children}
        </Stack>
      </Grid>
    </Grid>
  );
};
