import { NomenclaturePaths } from '@/router';
import { Suspense } from 'react';
import { ProgressBar } from '@/components/ProgressBar';
import NomenclaturePage from '@/pages/nomenclature';
import NomenclatureCatalogPage from '@/pages/nomenclature/catalog';
import NomenclatureDeduplicationPage from '@/pages/nomenclature/deduplication';
import NomenclatureBindingPage from '@/pages/nomenclature/binding';
import { BreadcrumbLink, RouteObjectWithBreadcrumbs } from '@/components/Breadcrumbs';

export const NomenclatureRoute: RouteObjectWithBreadcrumbs = {
  path: NomenclaturePaths.ROOT_PATH,
  element: <NomenclaturePage />,
  children: [
    {
      path: NomenclaturePaths.ROOT_PATH,
      handle: {
        breadcrumb: {
          Component: () => <BreadcrumbLink to={NomenclaturePaths.ROOT_PATH}>Номенклатура</BreadcrumbLink>,
        },
      },
      children: [
        {
          index: true,
          element: (
            <Suspense fallback={<ProgressBar />}>
              <NomenclatureCatalogPage />
            </Suspense>
          ),
        },
        {
          path: NomenclaturePaths.DEDUPLICATION_PATH,
          handle: {
            breadcrumb: {
              Component: () => <BreadcrumbLink to={NomenclaturePaths.DEDUPLICATION_PATH}>Дедупликация</BreadcrumbLink>,
            },
          },
          element: (
            <Suspense fallback={<ProgressBar />}>
              <NomenclatureDeduplicationPage />
            </Suspense>
          ),
        },
        {
          path: NomenclaturePaths.BINDING_PATH,
          handle: {
            breadcrumb: {
              Component: () => <BreadcrumbLink to={NomenclaturePaths.BINDING_PATH}>Привязка товара</BreadcrumbLink>,
            },
          },
          element: (
            <Suspense fallback={<ProgressBar />}>
              <NomenclatureBindingPage />
            </Suspense>
          ),
        },
        {
          path: NomenclaturePaths.DRUG_PATH,
          element: (
            <Suspense fallback={<ProgressBar />}>
              <NomenclatureCatalogPage />
            </Suspense>
          ),
        },
      ],
    },
  ],
};
