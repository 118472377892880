import { RouteObjectWithBreadcrumbs } from '@/components/Breadcrumbs';
import { DorimPromoPaths } from '@/router/paths/dorim-promo.path';

export const ProcurementPlanPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.CONTRACT_PROCUREMENT_PLAN,
  lazy: async () => {
    const { ProcurementPlanPage } = await import(
      /* webpackChunkName: "ProcurementPlanPage" */
      './ProcurementPlanPage'
    );

    return {
      Component: ProcurementPlanPage,
    };
  },
};
