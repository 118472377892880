import { Stack } from '@mui/material';

import { DocumentToolbar, DocumentToolbarButton } from '@/components/DocumentToolbar';
import { CashbackStatus } from '@/components/statuses';

import { ToolbarTabs } from './ui';
import { ContractPathParams, DorimPromoPaths } from '@/router/paths/dorim-promo.path';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { usePromoContract } from '@/hooks/usePromoContract';
import { DocumentToolbarSkeleton } from '@/pages/promo-contracts/shared';

export const Toolbar = () => {
  const { contractId } = useParams<ContractPathParams>();
  const { isInitialLoading, data } = usePromoContract({ contractId: Number(contractId) });

  if (isInitialLoading) {
    return <DocumentToolbarSkeleton />;
  }

  if (!data?.agreement || !contractId) {
    return null;
  }
  const renderDocumentButtons = () => (
    <Stack direction="row" spacing={1}>
      <DocumentToolbarButton
        component={RouterLink}
        to={DorimPromoPaths.generateContractDocumentPath({ contractId })}
        disabled={!data.agreement?.contractor?.agreements_data?.document_src}
      >
        Договор
      </DocumentToolbarButton>
      <DocumentToolbarButton
        component={RouterLink}
        to={DorimPromoPaths.generateSpecificationDocumentPath({ contractId })}
        disabled={!data.agreement?.agreement_text_generated_url}
      >
        Спецификация
      </DocumentToolbarButton>
    </Stack>
  );

  return (
    <DocumentToolbar
      title={data.agreement.contractor?.name}
      documentNumber={data.agreement.document_number}
      button={renderDocumentButtons()}
      footer={<ToolbarTabs />}
    >
      <CashbackStatus statusId={data.agreement.record_status_id} />
    </DocumentToolbar>
  );
};
