import { Stack, Typography } from '@mui/material';
import { DocumentToolbarButton } from './DocumentToolbarButton';
import { Infobar, type InfobarProps } from '../InfoBar';

type DocumentToolbarProps = InfobarProps & {
  title: React.ReactNode;
  documentNumber?: React.ReactNode;
  date?: string;
};

export const DocumentToolbar = (props: DocumentToolbarProps) => {
  const {
    title,
    documentNumber,
    slotProps,
    children,
    button = <DocumentToolbarButton {...slotProps?.button} />,
    date,
    ...restInfobarProps
  } = props;

  return (
    <Infobar button={button} {...restInfobarProps}>
      <Stack direction="row" alignItems="baseline" spacing={2}>
        {title && (
          <Typography component="div" variant="Headline/Bold/small">
            {title}
          </Typography>
        )}
        {documentNumber && (
          <Typography component={Stack} direction="row" variant="Body/medium">
            №{documentNumber}
          </Typography>
        )}
        <Stack direction="row" alignItems="baseline" spacing={1}>
          {date && (
            <>
              <Typography variant="Body/medium" color="neutral.700">
                {date}
              </Typography>
              <Typography variant="Body/medium" color="neutral.300">
                |
              </Typography>
            </>
          )}
        </Stack>

        {children}
      </Stack>
    </Infobar>
  );
};
