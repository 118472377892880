import { FetchSalesPlanMarketingOrgParams, PromoContractsApi } from '@/api/domains/promo-contracts.api';
import { PromoContractsQueryKeys } from '@/api/domains/promo-contracts.query-keys';
import { PromoContractSalesPlanMarketingOrg } from '@/api/domains/promo-contracts.types';
import { useQuery } from '@tanstack/react-query';

type UseSalesPlanMarketingOrgParams<TData> = FetchSalesPlanMarketingOrgParams & {
  select?: (marketingOrg: PromoContractSalesPlanMarketingOrg | undefined) => TData | undefined;
};

export const useSalesPlanMarketingOrg = <TData = PromoContractSalesPlanMarketingOrg | undefined>({
  agreement_id,
  marketing_org_id,
  select,
}: UseSalesPlanMarketingOrgParams<TData>) =>
  useQuery({
    queryKey: PromoContractsQueryKeys.getSalesPlanMarketingOrgKey({ agreement_id, marketing_org_id }),
    queryFn: () => PromoContractsApi.fetchSalesPlanMarketingOrg({ agreement_id, marketing_org_id }),
    select,
    staleTime: 1000 * 60 * 5,
  });
