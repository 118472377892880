import { Label } from '@/components/labels';
import {
  PROMO_CONTRACT_STATUS_UNKNOWN,
  PromoContractStatusId,
  PromoContractStatusNameByStatusId,
} from '@/constants/promo-contract-status.constants';
import { getColorByPromoContractStatusId } from '@/pages/promo-contracts/helpers/status-select.helpers';

type CashbackStatusProps = {
  statusId: PromoContractStatusId;
};

export const CashbackStatus = (props: CashbackStatusProps) => {
  const { statusId } = props;

  const statusLabel = PromoContractStatusNameByStatusId[statusId];

  const color = getColorByPromoContractStatusId(statusId) || 'default';

  return <Label color={color}>{statusLabel || PROMO_CONTRACT_STATUS_UNKNOWN}</Label>;
};
