import { useTheme } from '@mui/material';
import { last } from 'lodash';

import type { Plan } from '@/api/domains/promo-contracts.types';

export const getActivePlan = (orderedCount: number, plans: Plan[]) => {
  if (plans.length === 0) return;

  for (let i = 0; i < plans.length; i++) {
    const currentPlan = plans[i];
    const nextPlan = plans[i + 1];

    if (!nextPlan) return currentPlan;

    const currentPlanCount = currentPlan.count;
    const nextPlanCount = nextPlan.count;

    if (orderedCount >= currentPlanCount && orderedCount < nextPlanCount) {
      return currentPlan;
    }
  }
};

type UseSliderColorParams = {
  currentValue: number;
  values: number[];
  shouldUseDefaultColor?: boolean;
};

export const useSliderColor = (params: UseSliderColorParams) => {
  const { currentValue, values, shouldUseDefaultColor } = params;
  const theme = useTheme();
  const defaultColor = theme.palette.tetriary.main;
  const lastValue = last(values);

  if (currentValue === 0) return theme.palette.warning.main;
  if (values.length <= 1) return defaultColor;
  if (lastValue && lastValue === Infinity) return defaultColor;

  const getSliderColor = (currentValue: number, thresholdValue: number, maxValue: number) => {
    if (shouldUseDefaultColor && currentValue < maxValue) {
      return defaultColor;
    } else if (currentValue < thresholdValue) {
      return theme.palette.warning.main;
    } else if (currentValue >= maxValue) {
      return theme.palette.custom.green;
    }

    return defaultColor;
  };

  const maxCount = values[values.length - 1];
  const thresholdCount = values[1];
  if (values.length === 2) {
    return getSliderColor(currentValue, maxCount, maxCount);
  }

  return getSliderColor(currentValue, thresholdCount, maxCount);
};
