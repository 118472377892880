import { useState } from 'react';
import { TransformComponent, TransformWrapper, useControls } from 'react-zoom-pan-pinch';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Dialog, IconButton, Stack, styled, Typography, type DialogProps } from '@mui/material';

const StyledImage = styled('img')(({ theme }) => ({
  border: `1px solid ${theme.palette.neutral[300]}`,
  borderRadius: theme.shape.borderRadius2,
  aspectRatio: '4 / 3',
  maxWidth: '100%',
  width: '100%',
  height: 'auto',
}));

const StyledButton = styled(IconButton)(({ theme }) => ({
  background: theme.palette.neutral[100],
  borderRadius: theme.shape.borderRadius2,
  padding: 0,
  '&:hover': {
    background: theme.palette.neutral[200],
  },
}));

const StyledFixedContainer = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  top: theme.spacing(2),

  zIndex: theme.zIndex.modal,
  right: theme.spacing(2),
  bottom: theme.spacing(2),
}));

const StyledBadgeLabel = styled(Typography)(({ theme }) => ({
  background: theme.palette.neutral[100],
  borderRadius: theme.shape.borderRadius2,
  top: theme.spacing(2),
  left: theme.spacing(2),
  zIndex: theme.zIndex.modal,
  position: 'fixed',
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
}));

type ZoomImageDialogProps = Omit<DialogProps, 'onClose'> & {
  imageUrl: string | null;
  initialScale?: number;
  badgeLabel?: string;
  onClose: () => void;
};

type ControlsProps = {
  onClose: () => void;
};

const Controls = (props: ControlsProps) => {
  const { onClose } = props;
  const { zoomIn, zoomOut } = useControls();

  return (
    <StyledFixedContainer justifyContent="space-between">
      <StyledButton onClick={onClose}>
        <CloseIcon />
      </StyledButton>
      <Stack spacing={1}>
        <StyledButton onClick={() => zoomIn()}>
          <ZoomInIcon />
        </StyledButton>
        <StyledButton onClick={() => zoomOut()}>
          <ZoomOutIcon />
        </StyledButton>
      </Stack>
    </StyledFixedContainer>
  );
};

export const ZoomImageFullScreenDialog = (props: ZoomImageDialogProps) => {
  const { imageUrl, initialScale = 1, badgeLabel, onClose, maxWidth = 'desktop', ...restProps } = props;
  const [scale, setScale] = useState(initialScale);
  const isFullScreen = scale > 1;

  if (!imageUrl) return null;

  return (
    <Dialog
      componentsProps={{ backdrop: { style: { backgroundColor: 'rgba(0,0,0,0.8)' } } }}
      fullWidth
      maxWidth={maxWidth}
      {...restProps}
      PaperProps={{
        ...restProps.PaperProps,
        style: {
          ...restProps.PaperProps?.style,
          maxHeight: isFullScreen ? '100%' : 'calc(100% - 112px)',
        },
      }}
      fullScreen={isFullScreen}
    >
      <TransformWrapper
        onTransformed={({ state }) => {
          setScale(state.scale);
        }}
        centerOnInit={true}
        initialScale={initialScale}
      >
        <TransformComponent contentStyle={{ width: '100%' }} wrapperStyle={{ width: '100%' }}>
          <StyledImage src={imageUrl} />
        </TransformComponent>
        <StyledBadgeLabel variant="Body/Bold/small">{badgeLabel}</StyledBadgeLabel>
        <Controls onClose={onClose} />
      </TransformWrapper>
    </Dialog>
  );
};
