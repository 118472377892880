import { Tab, Tabs } from '@mui/material';

import { ContractMarketingOrgPathParams, DorimPromoPaths } from '@/router/paths/dorim-promo.path';
import { Link, useParams } from 'react-router-dom';
import { usePromoContract } from '@/hooks/usePromoContract';
import { useMatchLinks } from '@/hooks/useMatchLinks';

export const ToolbarTabs = () => {
  const { contractId } = useParams<ContractMarketingOrgPathParams>();
  const stringifiedContractId = String(contractId);

  const { data } = usePromoContract({
    contractId: Number(contractId),
  });

  const { has_display_tasks, has_purchase_drugs, has_sale_drugs } = data?.agreement ?? {};

  const procurementPlanPath = DorimPromoPaths.generateContractProcurementPlanPath({
    contractId: stringifiedContractId,
  });
  const planogramPath = DorimPromoPaths.generateContractPlanogramPath({
    contractId: stringifiedContractId,
  });
  const salesPlanPath = DorimPromoPaths.generateContractSalesPlanPath({
    contractId: stringifiedContractId,
  });
  const matchedLink = useMatchLinks(procurementPlanPath, planogramPath, salesPlanPath);

  if (!contractId || !matchedLink?.pathname || !(has_display_tasks || has_purchase_drugs || has_sale_drugs)) {
    return null;
  }

  return (
    <Tabs value={matchedLink.pathname} sx={{ pl: 1 }} textColor="primary" indicatorColor="primary">
      {has_purchase_drugs && (
        <Tab component={Link} to={procurementPlanPath} value={procurementPlanPath} replace label="План закупки" />
      )}
      {has_display_tasks && (
        <Tab component={Link} to={planogramPath} value={planogramPath} replace label="План выкладки" />
      )}
      {has_sale_drugs && <Tab component={Link} to={salesPlanPath} value={salesPlanPath} replace label="План продажи" />}
    </Tabs>
  );
};
