import { Stack, SvgIcon, Tooltip, Typography } from '@mui/material';

import type { MarketingOrgInfo, SumPlanState } from '@/api/domains/promo-contracts.types';
import { ReactComponent as DoneSVG } from '@/assets/icons-2.0/done.svg';

const getDescription = (planState: SumPlanState) => {
  switch (planState) {
    case 'underMinPlan':
      return 'При достижении минимального плана, вы получите бонус по текущему прогрессу в товарах';
    case 'minPlanCompletion':
      return 'По достижению максимального значения вы получите максимальный % бонуса по всем товарам';
    case 'maxPlanCompletion':
      return 'Вы достигли максимального плана и получите максимальный % бонуса по всем товарам';
    default:
      return 'Выполняйте индивидуальный план по каждому товару и получите указанный % бонуса';
  }
};

type MarketingOrgCardInfoBlockProps = {
  marketingOrgName?: MarketingOrgInfo['full_name'];
  sumPlanState: SumPlanState;
};

export const MarketingOrgCardInfoBlock = (props: MarketingOrgCardInfoBlockProps) => {
  const { marketingOrgName = '-', sumPlanState } = props;

  return (
    <Stack spacing={2}>
      <Stack>
        <Stack spacing={1} alignItems="center" direction="row">
          <Tooltip arrow placement="top" title={marketingOrgName}>
            <Typography noWrap variant="Title/Bold/large">
              {marketingOrgName}
            </Typography>
          </Tooltip>

          {sumPlanState === 'maxPlanCompletion' && <SvgIcon component={DoneSVG} sx={{ color: 'custom.green' }} />}
        </Stack>
        <Typography variant="Body/medium" color="neutral.700">
          Маркетирующая организация
        </Typography>
      </Stack>

      <Typography variant="Body/medium">{getDescription(sumPlanState)}</Typography>
    </Stack>
  );
};
