import { useCallback } from 'react';
import { type RenderZoomInProps, type RenderZoomOutProps, zoomPlugin as useZoomPlugin } from '@react-pdf-viewer/zoom';
import { ZoomIn as ZoomInIcon, ZoomOut as ZoomOutIcon } from '@mui/icons-material';
import { ToolbarButton } from './ToolbarButton';

export const useToolbarZoom = () => {
  const zoomPluginInstance = useZoomPlugin();
  const { ZoomIn, ZoomOut } = zoomPluginInstance;

  const ZoomInButton = useCallback(() => {
    return (
      <ZoomIn>
        {(props: RenderZoomInProps) => (
          <ToolbarButton onClick={props.onClick}>
            <ZoomInIcon />
          </ToolbarButton>
        )}
      </ZoomIn>
    );
  }, [zoomPluginInstance, ZoomIn]);

  const ZoomOutButton = useCallback(
    () => (
      <ZoomOut>
        {(props: RenderZoomOutProps) => (
          <ToolbarButton onClick={props.onClick}>
            <ZoomOutIcon />
          </ToolbarButton>
        )}
      </ZoomOut>
    ),
    [zoomPluginInstance, ZoomOut],
  );

  return {
    zoomPluginInstance,
    ZoomInButton,
    ZoomOutButton,
  };
};
