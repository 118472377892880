import { styled, Typography } from '@mui/material';

const StyledRoot = styled('div')(({ theme }) => ({
  top: theme.dimensions.header.height,
  position: 'sticky',
  zIndex: theme.zIndex.appBar,
  padding: theme.spacing(1, 1, 0),
  marginTop: theme.spacing(-1),
  marginLeft: theme.spacing(-1),
  marginRight: theme.spacing(-1),
  backgroundColor: theme.palette.grey[200],
}));

const StyledCardHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: 70,
  margin: -1,
  padding: theme.spacing(3),
  border: theme.shape.divider,
  borderRadius: '16px 16px 0px 0px',
  backgroundColor: theme.palette.background.paper,
}));

export const CardHeader = () => (
  <StyledRoot>
    <StyledCardHeader>
      <Typography variant="Title/Bold/large">Условия по закупке товаров</Typography>
    </StyledCardHeader>
  </StyledRoot>
);
