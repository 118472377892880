import { Outlet, useParams } from 'react-router-dom';
import { Stack } from '@mui/material';

import { Header } from '@/layouts/Header';
import { PageContainer, PageContent } from '@/layouts/Page';
import { Toolbar } from './ui';
import { ContractMarketingOrgPathParams } from '@/router/paths/dorim-promo.path';
import { usePromoContract } from '@/hooks/usePromoContract';
import { usePromoContractStoreResetOnUnmount } from '../usePromoContractStore';
import { ContentTotalAmountFloatingPanel } from '../components';

export const Layout = () => {
  const { contractId } = useParams<ContractMarketingOrgPathParams>();

  const { data: promoContract } = usePromoContract({
    contractId: Number(contractId),
  });

  usePromoContractStoreResetOnUnmount();

  return (
    <PageContainer title="Dorim.Promo • Спецификация">
      <Header hasBreadcrumbs />

      <PageContent>
        <Stack spacing={1} height="100%">
          <Toolbar />
          <Outlet />
          {promoContract?.agreement && <ContentTotalAmountFloatingPanel contract={promoContract.agreement} />}
        </Stack>
      </PageContent>
    </PageContainer>
  );
};
