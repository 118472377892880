import { Fragment, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Card, Typography, styled, Collapse } from '@mui/material';

import { PromoContract } from '@/api/domains/promo-contracts.types';
import { useShouldPromoContractTotalAmountPanelBeVisible } from '../usePromoContractStore';
import { ContentTotalAmount } from '../../shared';
const PANEL_HEIGHT = 72;

const StyledRoot = styled('div', {
  shouldForwardProp: prop => prop !== 'isFloating',
})<{
  isFloating: boolean;
}>(
  {
    height: PANEL_HEIGHT,
    display: 'flex',
    alignItems: 'flex-end',
  },
  ({ isFloating, theme }) =>
    isFloating && {
      position: 'sticky',
      bottom: theme.spacing(1),
      zIndex: theme.zIndex.appBar,
    },
);

const StyledCard = styled(Card)(({ theme }) => ({
  height: PANEL_HEIGHT,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
  borderRadius: 16,
}));

type ContentTotalAmountFloatingPanelProps = {
  contract: PromoContract;
};

export const ContentTotalAmountFloatingPanel = (props: ContentTotalAmountFloatingPanelProps) => {
  const {
    contract: { cashback_sum },
  } = props;

  const shouldBeVisible = useShouldPromoContractTotalAmountPanelBeVisible();
  const [isFloatingState, setIsFloatingState] = useState(false);

  useEffect(() => {
    if (shouldBeVisible) {
      setIsFloatingState(true);
    }
  }, [shouldBeVisible]);

  const { ref, inView } = useInView({
    initialInView: true,
  });

  const isFloating = isFloatingState || shouldBeVisible;
  const isExpanded = inView || shouldBeVisible;

  return (
    <Fragment>
      <div ref={ref} style={{ margin: 0 }} />
      <StyledRoot isFloating={isFloating}>
        <Collapse in={isExpanded} sx={{ flexGrow: 1 }} onExited={() => setIsFloatingState(false)}>
          <StyledCard variant="outlined">
            <Typography component="p" variant="h5">
              Бонус по условиям договора
            </Typography>
            <ContentTotalAmount cashbackSum={cashback_sum} />
          </StyledCard>
        </Collapse>
      </StyledRoot>
    </Fragment>
  );
};
