import { NamesByRecordStatusId, RECORD_STATUS_UNKNOWN, RecordStatusId } from '@/constants/record-status.constants';
import { getColorByRecordStatusId } from '@/helpers/common.helpers';
import { Label } from '../labels';

type RecordStatusProps = {
  recordStatusId: RecordStatusId;
};

export const RecordsStatus = (props: RecordStatusProps) => {
  const { recordStatusId } = props;
  const statusName = NamesByRecordStatusId[recordStatusId] || RECORD_STATUS_UNKNOWN;
  const color = getColorByRecordStatusId(recordStatusId);

  return <Label color={color}>{statusName}</Label>;
};
