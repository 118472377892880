import { Suspense } from 'react';
import { RouterProvider } from 'react-router';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { ProgressBar } from '@/components/ProgressBar';
import { AuthLayout } from '@/layouts/AuthLayout';
import { MainLayout } from '@/layouts/MainLayout';
import { AuthGuard } from '@/guards/AuthGuard';
import { GuestGuard } from '@/guards/GuestGuard';
import SignInPage from '@/pages/auth/sign-in';
import BindingsPage from '@/pages/bindings';
import ClassificationsPage from '@/pages/classifications';
import ContractorsPage from '@/pages/contractors';
import InnPage from '@/pages/inn';
import MakersPage from '@/pages/makers';
import OffersPage from '@/pages/offers';
import PriceListsPage from '@/pages/price-lists';
import { ProfilePageLazy, ProfilePaths } from '@/pages/profile';
import { AuthPaths } from '@/router/paths/auth.paths';
import { BindingsPaths } from '@/router/paths/bindings.paths';
import { ClassificationsPaths } from '@/router/paths/classifications.paths';
import { ContractorsPaths } from '@/router/paths/contractors.paths';
import { InnPaths } from '@/router/paths/inn.paths';
import { MakersPaths } from '@/router/paths/makers.paths';
import { NomenclaturePaths } from '@/router/paths/nomenclature.paths';
import { OffersPaths } from '@/router/paths/offers.paths';
import { PriceListsPaths } from '@/router/paths/price-lists.paths';
import MakersCatalogPage from '@/pages/makers/catalog/MakersCatalogPage';
import MakersDeduplicationPage from '@/pages/makers/deduplication';
import { ReportsPaths } from '@/router/paths/reports.paths';
import ReportsDistributorsPage from '@/pages/distributor-reports';

import { RegistersPaths } from './paths/registers.paths';
import { RegistersPage } from '@/pages/registers/RegistersPage';
import { RegistryImportLogLayout } from '@/layouts/RegistryImportLogLayout';
import { RegistryImportLogGeneralInfoPage, RegistryImportLogErrorsPage } from '@/pages/registers/import-log';
import { ReferencePriceRegistryViewPage } from '@/pages/registers/RegistryView/ReferencePrice';
import { RegistryFieldMatchingPage } from '@/pages/registers/FieldMatching';
import { RegistryFileGuard } from '@/guards/RegistryFileGuard';
import { RegistryBindingPage } from '@/pages/registers/RegistryBinding';
import { DorimPromoRoute, EimzoDocumentsRoute, NomenclatureRoute } from '@/router/routes';

const router = createBrowserRouter([
  {
    path: AuthPaths.ROOT_PATH,
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      { index: true, element: <Navigate replace to={AuthPaths.SIGN_IN_PATH} /> },
      {
        path: AuthPaths.SIGN_IN_PATH,
        element: (
          <Suspense fallback={<ProgressBar />}>
            <SignInPage />
          </Suspense>
        ),
      },
    ],
  },

  // ----------------------------------------------------------------------

  { path: '/', element: <Navigate replace to={NomenclaturePaths.ROOT_PATH} /> },

  // ----------------------------------------------------------------------

  {
    path: '/',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      NomenclatureRoute,
      {
        path: ClassificationsPaths.ROOT_PATH,
        element: (
          <Suspense fallback={<ProgressBar />}>
            <ClassificationsPage />
          </Suspense>
        ),
      },
      {
        path: MakersPaths.ROOT_PATH,
        element: (
          <Suspense fallback={<ProgressBar />}>
            <MakersPage />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <MakersCatalogPage />
              </Suspense>
            ),
          },
          {
            path: MakersPaths.DEDUPLICATION_PATH,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <MakersDeduplicationPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: InnPaths.ROOT_PATH,
        element: (
          <Suspense fallback={<ProgressBar />}>
            <InnPage />
          </Suspense>
        ),
      },
      {
        path: BindingsPaths.ROOT_PATH,
        element: (
          <Suspense fallback={<ProgressBar />}>
            <BindingsPage />
          </Suspense>
        ),
      },

      // ----------------------------------------------------------------------

      {
        path: ContractorsPaths.ROOT_PATH,
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <ContractorsPage />
              </Suspense>
            ),
          },
          {
            path: ContractorsPaths.SEARCH_PATH,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <ContractorsPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: RegistersPaths.ROOT_PATH,
        children: [
          {
            index: true,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <RegistersPage />
              </Suspense>
            ),
          },
          {
            path: RegistersPaths.IMPORT_LOG_PATH,
            element: (
              <AuthGuard>
                <RegistryImportLogLayout />
              </AuthGuard>
            ),
            children: [
              {
                index: true,
                element: <Navigate replace to={RegistersPaths.IMPORT_LOG_BASE_INFO_PATH} />,
              },
              {
                path: RegistersPaths.IMPORT_LOG_BASE_INFO_PATH,
                element: (
                  <Suspense fallback={<ProgressBar />}>
                    <RegistryImportLogGeneralInfoPage />
                  </Suspense>
                ),
              },
              {
                path: RegistersPaths.IMPORT_LOG_ERRORS_PATH,
                element: (
                  <Suspense fallback={<ProgressBar />}>
                    <RegistryImportLogErrorsPage />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: RegistersPaths.REFERENCE_PRICE_REGISTRY_VIEW_PATH,
            element: (
              <Suspense fallback={<ProgressBar />}>
                <ReferencePriceRegistryViewPage />
              </Suspense>
            ),
          },
          {
            path: RegistersPaths.FIELD_MATCHING_PATH,
            element: (
              <RegistryFileGuard>
                <Suspense fallback={<ProgressBar />}>
                  <RegistryFieldMatchingPage />
                </Suspense>
              </RegistryFileGuard>
            ),
          },
          {
            path: RegistersPaths.BINDING,
            element: (
              <RegistryFileGuard>
                <Suspense fallback={<ProgressBar />}>
                  <RegistryBindingPage />
                </Suspense>
              </RegistryFileGuard>
            ),
          },
        ],
      },

      // ----------------------------------------------------------------------

      DorimPromoRoute,

      {
        path: PriceListsPaths.ROOT_PATH,
        element: (
          <Suspense fallback={<ProgressBar />}>
            <PriceListsPage />
          </Suspense>
        ),
      },
      {
        path: OffersPaths.ROOT_PATH,
        element: (
          <Suspense fallback={<ProgressBar />}>
            <OffersPage />
          </Suspense>
        ),
      },

      EimzoDocumentsRoute,

      {
        path: ProfilePaths.ROOT,
        element: (
          <Suspense fallback={<ProgressBar />}>
            <ProfilePageLazy />
          </Suspense>
        ),
      },

      { path: ReportsPaths.ROOT, element: <Navigate replace to={ReportsPaths.DISTRIBUTORS.ROOT} /> },
      {
        path: ReportsPaths.DISTRIBUTORS.ROOT,
        element: (
          <Suspense fallback={<ProgressBar />}>
            <ReportsDistributorsPage />
          </Suspense>
        ),
      },
    ],
  },

  {
    path: '*',
    element: <Navigate replace to="/" />,
  },
]);

export const Router = () => <RouterProvider router={router} />;
