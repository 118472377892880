import { createStoreWithReset } from '@/libs/zustand';
import { useUnmount } from 'react-use';
import { devtools } from 'zustand/middleware';

type PromoContractStore = {
  shouldTotalAmountPanelBeVisible: boolean;

  actions: {
    showTotalAmountPanel: VoidFunction;
    hideTotalAmountPanel: VoidFunction;
  };
};

const [usePromoContractStore, resetPromoContractStore] = createStoreWithReset<PromoContractStore>()(
  devtools(
    set => ({
      // State
      shouldTotalAmountPanelBeVisible: false,

      // Actions
      actions: {
        showTotalAmountPanel: () => set({ shouldTotalAmountPanelBeVisible: true }, false, 'showTotalAmountPanel'),
        hideTotalAmountPanel: () => set({ shouldTotalAmountPanelBeVisible: false }, false, 'hideTotalAmountPanel'),
      },
    }),
    {
      name: 'usePromoContractStore',
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
);

export const usePromoContractStoreActions = () => usePromoContractStore(state => state.actions);

export const useShouldPromoContractTotalAmountPanelBeVisible = () =>
  usePromoContractStore(state => state.shouldTotalAmountPanelBeVisible);

export const usePromoContractStoreResetOnUnmount = () => useUnmount(resetPromoContractStore);
