import { FetchPlanogramTask, PromoContractsApi } from '@/api/domains/promo-contracts.api';
import { PromoContractsQueryKeys } from '@/api/domains/promo-contracts.query-keys';
import { useQuery } from '@tanstack/react-query';

export const usePlanogramTask = (params: FetchPlanogramTask) => {
  return useQuery({
    queryKey: PromoContractsQueryKeys.getPlanogramTask(params),
    queryFn: () => {
      return PromoContractsApi.fetchPlanogramTask(params);
    },
    staleTime: Infinity,
    enabled: !!params.task_id,
  });
};
