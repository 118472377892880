import { RouteObjectWithBreadcrumbs } from '@/components/Breadcrumbs';
import { usePromoContract } from '@/hooks/usePromoContract';
import { ContractMarketingOrgPathParams, DorimPromoPaths } from '@/router/paths/dorim-promo.path';
import { Navigate, useParams } from 'react-router';
import { Layout } from './layout/Layout';
import { ProcurementPlanPageRoute } from './procurement-plan';
import { SalesPlanPageRoute } from './sales-plan';
import { PlanogramTasksPageRoute } from './planogram';

const RedirectToCorrectPath = () => {
  const { contractId } = useParams<ContractMarketingOrgPathParams>();
  const stringifiedContractId = String(contractId);
  const { data, isLoading } = usePromoContract({
    contractId: Number(contractId),
  });

  const { has_display_tasks, has_purchase_drugs, has_sale_drugs } = data?.agreement ?? {};

  const procurementPlanPath = DorimPromoPaths.generateContractProcurementPlanPath({
    contractId: stringifiedContractId,
  });
  const planogramPath = DorimPromoPaths.generateContractPlanogramPath({
    contractId: stringifiedContractId,
  });
  const salesPlanPath = DorimPromoPaths.generateContractSalesPlanPath({
    contractId: stringifiedContractId,
  });

  const defaultPath = has_purchase_drugs
    ? procurementPlanPath
    : has_display_tasks
    ? planogramPath
    : has_sale_drugs
    ? salesPlanPath
    : DorimPromoPaths.CONTRACTS_PATH;

  if (isLoading) {
    return null;
  }

  return <Navigate to={defaultPath} replace />;
};

export const PromoContractPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.CONTRACT_PATH,
  element: <Layout />,
  children: [
    {
      index: true,
      element: <RedirectToCorrectPath />,
    },
    ProcurementPlanPageRoute,
    PlanogramTasksPageRoute,
    SalesPlanPageRoute,
  ],
};
