import { BreadcrumbLink, LazyBreadcrumb, RouteObjectWithBreadcrumbs } from '@/components/Breadcrumbs';
import { ContractSalesPlanProductViewPathParams, DorimPromoPaths } from '@/router/paths/dorim-promo.path';
import { useSalesPlanMarketingOrg } from './useSalesPlanMarketingOrg';

export const SalesPlanProductsPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.CONTRACT_SALES_PLAN_PRODUCT_VIEW_PATH,
  handle: {
    breadcrumb: (params: ContractSalesPlanProductViewPathParams) => ({
      Component: () => {
        const { data, isLoading } = useSalesPlanMarketingOrg({
          agreement_id: Number(params.contractId),
          marketing_org_id: Number(params.marketingOrgId),
        });

        return (
          <LazyBreadcrumb isLoading={isLoading}>
            <BreadcrumbLink to={DorimPromoPaths.generateMarketingOrgPath(params)}>
              {data?.marketing_org_details?.marketing_org?.full_name || 'Маркетирующая организация'}
            </BreadcrumbLink>
          </LazyBreadcrumb>
        );
      },
    }),
  },
  lazy: async () => {
    const { SalesPlanProductsPage } = await import(
      /* webpackChunkName: "SalesPlanProductsPage" */
      './SalesPlanProductsPage'
    );

    return {
      Component: SalesPlanProductsPage,
    };
  },
};
