import { BreadcrumbLink, LazyBreadcrumb, RouteObjectWithBreadcrumbs } from '@/components/Breadcrumbs';
import { ContractPlanogramTaskPathParams, DorimPromoPaths } from '@/router/paths/dorim-promo.path';
import { usePlanogramTask } from './usePlanogramTask';

export const PlanogramTaskPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.CONTRACT_PLANOGRAM_TASK_PATH,
  handle: {
    breadcrumb: (params: ContractPlanogramTaskPathParams) => ({
      Component: () => {
        const { data, isLoading } = usePlanogramTask({
          task_id: Number(params.taskId),
          agreement_id: Number(params.contractId),
        });

        return (
          <LazyBreadcrumb isLoading={isLoading}>
            <BreadcrumbLink to={DorimPromoPaths.generateContractPlanogramTaskPath(params)}>
              {data?.marketing_org?.name || 'Маркетирующая организация'}
            </BreadcrumbLink>
          </LazyBreadcrumb>
        );
      },
    }),
  },
  lazy: async () => {
    const { PlanogramTaskPage } = await import(
      /* webpackChunkName: "PlanogramTaskPage" */
      './PlanogramTaskPage'
    );

    return {
      Component: PlanogramTaskPage,
    };
  },
};
