import { httpClient } from '@/libs/axios/http-client';
import type { PaginatedList, PaginationParams } from '../@types/pagination.types';
import type {
  ContractDocument,
  DorimPromoDrugOrder,
  MarketingOrg,
  PlanogramTask,
  PromoContract,
  PromoContractDetails,
  PromoContractDrugAnalytics,
  PromoContractPayment,
  PromoContractSalesPlan,
  PromoContractSalesPlanMarketingOrg,
} from './promo-contracts.types';
import type { PromoContractStatusId } from '@/constants/promo-contract-status.constants';

export type PromoContractsFetchParams = PaginationParams & {
  query?: string;
  date_from?: string;
  date_to?: string;
  record_statuses?: string;
};

export type FetchPromoContractParams = {
  contractId: number;
};

export type PromoContractUpdateParams = {
  record_status_id: PromoContractStatusId;
};

export type PromoContractsUpdateParams = {
  record_status_id: PromoContractStatusId;
  specification_template_ids: number[];
};

export type FetchDrugAnalyticsParams = {
  contractId: number;
  drugId: number;
};

export type FetchDrugOrdersParams = {
  contractId: number;
  drugId: number;
};

export type FetchContractDocumentParams = {
  contractId: number;
};

export type FetchMarketingOrgAgreementParams = {
  agreementId: number;
  marketingOrgId: number;
};

export type MarketingOrgAgreementResponse = {
  agreement: PromoContract | null;
  marketing_org_details: MarketingOrg | null;
};

export type CalculateBonusParams = {
  specification_template_ids: number[];
};

export type UpdatePromoContractPaymentParams = {
  contractId: number;
  cashbacks: PromoContractPayment[];
};

export type FetchSalesPlanParams = {
  agreement_id: number;
};

export type FetchSalesPlanMarketingOrgParams = {
  agreement_id: number;
  marketing_org_id: number;
};

export type FetchPlanogramTasks = PaginationParams & {
  agreement_id: number;
};

export type FetchPlanogramTask = {
  agreement_id: number;
  task_id: number;
};

export const PromoContractsApi = {
  fetchPromoContracts: async (params?: PromoContractsFetchParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', PromoContract>>('agreement', { params });

    return data;
  },

  fetchPromoContract: async ({ contractId }: FetchPromoContractParams) => {
    const { data } = await httpClient.get<PromoContractDetails>(`agreement/${contractId}`);

    return data;
  },

  updatePromoContracts: (params: PromoContractsUpdateParams) => httpClient.put<void>('agreement', params),

  updatePromoContract: async (contractId: number, params: PromoContractUpdateParams) => {
    const { data } = await httpClient.put<PromoContract>(`agreement/${contractId}`, params);

    return data;
  },

  updatePromoContractPayment: async ({ contractId, ...formData }: UpdatePromoContractPaymentParams) =>
    httpClient.put<void>(`agreement/${contractId}/cashback`, {
      ...formData,
    }),

  fetchContractDocument: async (params: FetchContractDocumentParams) => {
    const { data } = await httpClient.get<ContractDocument>(`agreement/${params.contractId}/document`);

    return data;
  },

  fetchDrugAnalytics: async (params: FetchDrugAnalyticsParams) => {
    const { data } = await httpClient.get<PromoContractDrugAnalytics>(
      `agreement/${params.contractId}/statistics/${params.drugId}`,
    );

    return data;
  },

  fetchDrugOrders: async (params: FetchDrugOrdersParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', DorimPromoDrugOrder>>(
      `agreement/${params.contractId}/statistics/${params.drugId}/orders`,
    );

    return data;
  },

  fetchMarketingOrgAgreement: async (params: FetchMarketingOrgAgreementParams) => {
    const { data } = await httpClient.get<MarketingOrgAgreementResponse>(
      `agreement/${params.agreementId}/marketing_org/${params.marketingOrgId}`,
    );

    return data;
  },

  fetchSalesPlan: async ({ agreement_id }: FetchSalesPlanParams) => {
    const { data } = await httpClient.get<PromoContractSalesPlan>(`agreement/${agreement_id}/sales`);

    return data;
  },

  fetchPlanogramTasks: async ({ agreement_id, ...params }: FetchPlanogramTasks) => {
    const { data } = await httpClient.get<PaginatedList<'items', PlanogramTask>>(
      `agreements/${agreement_id}/display-tasks`,
      { params },
    );

    return data;
  },

  fetchPlanogramTask: async ({ agreement_id, task_id }: FetchPlanogramTask) => {
    const { data } = await httpClient.get<PlanogramTask>(`agreements/${agreement_id}/display-tasks/${task_id}`);

    return data;
  },

  fetchSalesPlanMarketingOrg: async ({ agreement_id, marketing_org_id }: FetchSalesPlanMarketingOrgParams) => {
    const { data } = await httpClient.get<PromoContractSalesPlanMarketingOrg>(
      `agreement/${agreement_id}/sales/${marketing_org_id}`,
    );

    return data;
  },

  calculateBonus: async (params: CalculateBonusParams) => {
    const { data } = await httpClient.post<string>('agreement/calculate-bonus', params);

    return data;
  },
};
