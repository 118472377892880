import { getFilePlugin as useGetFilePlugin, type RenderDownloadProps } from '@react-pdf-viewer/get-file';
import { Download as DownloadIcon } from '@mui/icons-material';
import { useCallback } from 'react';
import { ToolbarButton } from './ToolbarButton';

export const useToolbarDownload = () => {
  const downloadPluginInstance = useGetFilePlugin();
  const { Download } = downloadPluginInstance;

  const DownloadButton = useCallback(
    () => (
      <Download>
        {(props: RenderDownloadProps) => (
          <ToolbarButton onClick={props.onClick}>
            <DownloadIcon />
          </ToolbarButton>
        )}
      </Download>
    ),
    [downloadPluginInstance, Download],
  );

  return {
    downloadPluginInstance,
    DownloadButton,
  };
};
