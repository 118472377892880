import { RouteObjectWithBreadcrumbs } from '@/components/Breadcrumbs';
import { DorimPromoPaths } from '@/router/paths/dorim-promo.path';

export const PlanogramTasksPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.CONTRACT_PLANOGRAM_PATH,
  lazy: async () => {
    const { PlanogramTasksPage } = await import(
      /* webpackChunkName: "PlanogramTasksPage" */
      './PlanogramTasksPage'
    );

    return {
      Component: PlanogramTasksPage,
    };
  },
};
