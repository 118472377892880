import { useMemo } from 'react';

// ----------------------------------------------------------------------

type Ref<Value> = React.RefCallback<Value> | React.MutableRefObject<Value> | React.ForwardedRef<Value> | undefined;

const setRef = <Value>(ref: Ref<Value>, value: Value) => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
};

// ----------------------------------------------------------------------

export const useMergeRefs = <Value>(refA: Ref<Value>, refB: Ref<Value>): React.RefCallback<Value> | null =>
  useMemo<React.RefCallback<Value> | null>(() => {
    if (!refA && !refB) {
      return null;
    }

    return (refValue: Value) => {
      setRef(refA, refValue);
      setRef(refB, refValue);
    };
  }, [refA, refB]);
