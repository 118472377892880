import '@react-pdf-viewer/core/lib/styles/index.css';

import { useState } from 'react';
import { SpecialZoomLevel, Viewer, type ViewerProps, Worker } from '@react-pdf-viewer/core';
import { styled } from '@mui/material';
import { Toolbar, useToolbarPrint, useToolbarDownload, useToolbarZoom } from './toolbar';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';

const StyledRoot = styled('div')({
  position: 'relative',
  height: '100%',
});

export type PDFViewerProps = Pick<ViewerProps, 'fileUrl' | 'httpHeaders' | 'onDocumentLoad' | 'onPageChange'>;

export const PDFViewer = (props: PDFViewerProps) => {
  const [isDocumentLoaded, setIsDocumentsLoaded] = useState(false);

  const { printPluginInstance, PrintButton } = useToolbarPrint();
  const { downloadPluginInstance, DownloadButton } = useToolbarDownload();
  const { zoomPluginInstance, ZoomInButton, ZoomOutButton } = useToolbarZoom();

  const handleDocumentLoad = () => setIsDocumentsLoaded(true);

  return (
    <StyledRoot>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Viewer
          defaultScale={SpecialZoomLevel.PageFit}
          plugins={[printPluginInstance, downloadPluginInstance, zoomPluginInstance]}
          onDocumentLoad={handleDocumentLoad}
          {...props}
        />
      </Worker>
      {isDocumentLoaded && (
        <Toolbar>
          <ZoomOutButton />
          <ZoomInButton />
          <DownloadButton />
          <PrintButton />
        </Toolbar>
      )}
    </StyledRoot>
  );
};
