import { FetchMarketingOrgAgreementParams } from '@/api/domains/promo-contracts.api';
import { getSumPlanState } from '@/helpers/promo-contracts.helpers';
import { useMarketingOrgAgreement } from '@/hooks/useMarketingOrgAgreement';

type UseSumPlanStateParams = Partial<FetchMarketingOrgAgreementParams>;

const defaultSumPlanState = getSumPlanState({
  minPlan: 0,
  maxPlan: 0,
  orderSum: 0,
});

export const useSumPlanState = (params: UseSumPlanStateParams) => {
  const { data } = useMarketingOrgAgreement(params);

  if (!data?.marketing_org_details) {
    return defaultSumPlanState;
  }

  const { minimum_plan: minPlan, maximum_plan: maxPlan, order_sum: orderSum } = data.marketing_org_details;

  return getSumPlanState({ minPlan, maxPlan, orderSum });
};
