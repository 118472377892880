import { paramsToQueryKey } from '@/helpers/transformation.helpers';
import {
  FetchContractDocumentParams,
  FetchDrugAnalyticsParams,
  FetchPromoContractParams,
  PromoContractsFetchParams,
  FetchMarketingOrgAgreementParams,
  FetchDrugOrdersParams,
  FetchSalesPlanParams,
  FetchSalesPlanMarketingOrgParams,
  FetchPlanogramTasks,
  FetchPlanogramTask,
} from './promo-contracts.api';

export const PREFIX = 'promo-contracts' as const;

export const PromoContractsQueryKeys = {
  root: [PREFIX],

  getContractsKey: (params?: PromoContractsFetchParams) => paramsToQueryKey([PREFIX, 'list'], params),
  getContractKey: (params?: Partial<FetchPromoContractParams>) => paramsToQueryKey([PREFIX, 'contract'], params),
  getDrugAnalyticsKey: (params?: FetchDrugAnalyticsParams) => paramsToQueryKey([PREFIX, 'drug-analytics'], params),
  getDrugOrdersKey: (params?: FetchDrugOrdersParams) => paramsToQueryKey([PREFIX, 'drug-orders'], params),
  getContractDocumentKey: (params?: FetchContractDocumentParams) =>
    paramsToQueryKey([PREFIX, 'contract-document'], params),
  getMarketingOrgAgreementKey: (params: Partial<FetchMarketingOrgAgreementParams>) =>
    paramsToQueryKey([PREFIX, 'marketing-org'], params),
  getSalesPlanKey: (params: Partial<FetchSalesPlanParams>) => paramsToQueryKey([PREFIX, 'sales-plans'], params),
  getPlanogramTasks: (params: FetchPlanogramTasks) => paramsToQueryKey([PREFIX, 'planogram-tasks'], params),
  getPlanogramTask: (params: FetchPlanogramTask) => paramsToQueryKey([PREFIX, 'planogram-task'], params),
  getSalesPlanMarketingOrgKey: (params?: Partial<FetchSalesPlanMarketingOrgParams>) =>
    paramsToQueryKey([PREFIX, 'sales-plan-products'], params),
};
