import type { DorimPromoDrug, Plan, SalesPlanDrug, SumPlanState } from '@/api/domains/promo-contracts.types';

const ZERO_PLAN: Plan = {
  count: 0,
  bonus: 0,
  cashback: 0,
};

export const addZeroPlanToDrug = (drug: DorimPromoDrug) => ({
  ...drug,
  plans: drug.plans ? [ZERO_PLAN, ...drug.plans] : [ZERO_PLAN],
});

export const addZeroPlanToSalesPlanDrug = (drug: SalesPlanDrug) => ({
  ...drug,
  plans: drug.plans ? [ZERO_PLAN, ...drug.plans] : [ZERO_PLAN],
});

type GetSumPlanStateParams = {
  minPlan: number;
  maxPlan: number;
  orderSum: number;
};
export const getSumPlanState = (params: GetSumPlanStateParams): SumPlanState => {
  const { minPlan, maxPlan, orderSum } = params;

  if (minPlan && orderSum < minPlan) {
    return 'underMinPlan';
  } else if (maxPlan && orderSum < maxPlan) {
    return 'minPlanCompletion';
  } else if (maxPlan && orderSum >= maxPlan) {
    return 'maxPlanCompletion';
  }

  return 'default';
};
