import { generatePath, joinPath, ExtractPathParams } from '@/libs/react-router';

const ROOT_PATH = '/nomenclature';
const DEDUPLICATION_PATH = joinPath(ROOT_PATH, 'deduplication/:drugId');
const BINDING_PATH = joinPath(ROOT_PATH, 'binding/:drugId');
const DRUG_PATH = joinPath(ROOT_PATH, 'edit/:drugId');

export type NomenclatureDeduplicationPathParams = ExtractPathParams<typeof DEDUPLICATION_PATH>;
export type NomenclatureBindingPathParams = ExtractPathParams<typeof BINDING_PATH>;
export type EditNomenclaturePathParams = ExtractPathParams<typeof DRUG_PATH>;

export const NomenclaturePaths = {
  ROOT_PATH,
  DEDUPLICATION_PATH,
  BINDING_PATH,
  DRUG_PATH,

  generateDeduplicationPath: <TParams extends NomenclatureDeduplicationPathParams>(params: TParams) =>
    generatePath(DEDUPLICATION_PATH, params),

  generateBindingPath: <TParams extends NomenclatureBindingPathParams>(params: TParams) =>
    generatePath(BINDING_PATH, params),

  generateEditNomenclaturePath: <TParams extends EditNomenclaturePathParams>(params: TParams) =>
    generatePath(DRUG_PATH, params),
} as const;
