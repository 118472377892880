import { Avatar, Stack, SvgIcon, Typography, useTheme } from '@mui/material';

import { ReactComponent as VendorSVG } from '@/assets/icons-2.0/factory.svg';

type ContractMakerInfo = {
  makerName: string;
  makerCountry: string;
};

const MakerDefaultLogo = () => {
  const theme = useTheme();

  return (
    <Avatar sx={{ width: 20, height: 20, bgcolor: 'neutral.300' }}>
      <SvgIcon component={VendorSVG} inheritViewBox htmlColor={theme.palette.neutral[600]} sx={{ fontSize: 12 }} />
    </Avatar>
  );
};

export const ContractMakerInfo = (props: ContractMakerInfo) => {
  const { makerName, makerCountry } = props;

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <MakerDefaultLogo />
      <Typography variant="body2">
        {makerName} ({makerCountry})
      </Typography>
    </Stack>
  );
};
