import { RouteObjectWithBreadcrumbs } from '@/components/Breadcrumbs';
import { DorimPromoPaths } from '@/router/paths/dorim-promo.path';

export const SalesPlanPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.CONTRACT_SALES_PLAN_PATH,
  lazy: async () => {
    const { SalesPlanPage } = await import(
      /* webpackChunkName: "SalesPlanPage" */
      './SalesPlanPage'
    );

    return {
      Component: SalesPlanPage,
    };
  },
};
