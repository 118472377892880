import { generatePath } from 'react-router';
import { joinPath, ExtractPathParams } from '@/libs/react-router';

const ROOT_PATH = '/dorim-promo';
const CONTRACT_TEMPLATES_PATH = joinPath(ROOT_PATH, 'contract-templates');
const CONTRACTS_PATH = joinPath(ROOT_PATH, 'contracts');
const PROMO_PRODUCTS_PATH = joinPath(ROOT_PATH, 'products');
const CONTRACT_PATH = joinPath(CONTRACTS_PATH, ':contractId');

const CONTRACT_PROCUREMENT_PLAN = joinPath(CONTRACT_PATH, 'procurement-plan');
const CONTRACT_PLANOGRAM_PATH = joinPath(CONTRACT_PATH, 'planogram');
const CONTRACT_PLANOGRAM_TASK_PATH = joinPath(CONTRACT_PATH, 'planogram/:taskId');

export type ContractPlanogramTaskPathParams = ExtractPathParams<typeof CONTRACT_PLANOGRAM_TASK_PATH>;
const CONTRACT_SALES_PLAN_PATH = joinPath(CONTRACT_PATH, 'sales-plan');
const CONTRACT_SALES_PLAN_PRODUCT_VIEW_PATH = joinPath(CONTRACT_SALES_PLAN_PATH, 'products/:marketingOrgId');
export type ContractSalesPlanProductViewPathParams = ExtractPathParams<typeof CONTRACT_SALES_PLAN_PRODUCT_VIEW_PATH>;

const CONTRACT_DOCUMENT_PATH = joinPath(CONTRACT_PATH, 'contract-document');
const SPECIFICATION_DOCUMENT_PATH = joinPath(CONTRACT_PATH, 'specification-document');

const MARKETING_ORG_PATH = joinPath(CONTRACT_PATH, 'marketing-org/:marketingOrgId');
const ANALYTICS_PATH = joinPath(MARKETING_ORG_PATH, 'analytics/:drugId');

const PLANOGRAM_TASKS_CONTROL = joinPath(ROOT_PATH, 'planogram-tasks-control');
const PLANOGRAM_TASK_CONTROL_DETAIL_PATH = joinPath(PLANOGRAM_TASKS_CONTROL, ':taskId');

const PHARMACISTS_CONTROL_PATH = joinPath(ROOT_PATH, 'pharmacists-control');
const PHARMACISTS_CONTROL_DETAIL_PATH = joinPath(PHARMACISTS_CONTROL_PATH, ':pharmacistId');
export type PharmacistsControlDetailPathParams = ExtractPathParams<typeof PHARMACISTS_CONTROL_DETAIL_PATH>;
const PHARMACISTS_CONTROL_DETAIL_RECEIPTS_PATH = joinPath(PHARMACISTS_CONTROL_DETAIL_PATH, 'receipts');
const PHARMACISTS_CONTROL_DETAIL_WALLET_PATH = joinPath(PHARMACISTS_CONTROL_DETAIL_PATH, 'wallet');
const PHARMACISTS_CONTROL_DETAIL_RECEIPT_INFO_PATH = joinPath(PHARMACISTS_CONTROL_DETAIL_RECEIPTS_PATH, ':receiptId');

export type PharmacistsControlDetailReceiptInfoPathParams = ExtractPathParams<
  typeof PHARMACISTS_CONTROL_DETAIL_RECEIPT_INFO_PATH
>;
export type ContractDocumentPathParams = ExtractPathParams<typeof CONTRACT_DOCUMENT_PATH>;
export type SpecificationDocumentPathParams = ExtractPathParams<typeof SPECIFICATION_DOCUMENT_PATH>;
export type ContractPathParams = ExtractPathParams<typeof CONTRACT_PATH>;
export type ContractDrugAnalyticsPathParams = ExtractPathParams<typeof ANALYTICS_PATH>;
export type ContractMarketingOrgPathParams = ExtractPathParams<typeof MARKETING_ORG_PATH>;
export type PlanogramTaskControlDetailPathParams = ExtractPathParams<typeof PLANOGRAM_TASK_CONTROL_DETAIL_PATH>;

export const DorimPromoPaths = {
  ROOT_PATH,

  CONTRACT_DOCUMENT_PATH,
  generateContractDocumentPath: <TParams extends ContractDocumentPathParams>(params: TParams) =>
    generatePath(CONTRACT_DOCUMENT_PATH, params),

  SPECIFICATION_DOCUMENT_PATH,
  generateSpecificationDocumentPath: <TParams extends SpecificationDocumentPathParams>(params: TParams) =>
    generatePath(SPECIFICATION_DOCUMENT_PATH, params),

  CONTRACT_TEMPLATES_PATH,
  CONTRACTS_PATH,
  PROMO_PRODUCTS_PATH,

  CONTRACT_PATH,
  generateContractPath: <TParams extends ContractPathParams>(params: TParams) => generatePath(CONTRACT_PATH, params),

  CONTRACT_PROCUREMENT_PLAN,
  generateContractProcurementPlanPath: (params: ContractPathParams) => generatePath(CONTRACT_PROCUREMENT_PLAN, params),

  CONTRACT_PLANOGRAM_PATH,
  generateContractPlanogramPath: (params: ContractPathParams) => generatePath(CONTRACT_PLANOGRAM_PATH, params),
  CONTRACT_SALES_PLAN_PATH,
  generateContractSalesPlanPath: (params: ContractPathParams) => generatePath(CONTRACT_SALES_PLAN_PATH, params),

  CONTRACT_SALES_PLAN_PRODUCT_VIEW_PATH,
  generateContractSalesPlanProductViewPath: (params: ContractSalesPlanProductViewPathParams) =>
    generatePath(CONTRACT_SALES_PLAN_PRODUCT_VIEW_PATH, params),

  CONTRACT_PLANOGRAM_TASK_PATH,
  generateContractPlanogramTaskPath: (params: ContractPlanogramTaskPathParams) =>
    generatePath(CONTRACT_PLANOGRAM_TASK_PATH, params),

  MARKETING_ORG_PATH,
  generateMarketingOrgPath: <TParams extends ContractMarketingOrgPathParams>(params: TParams) =>
    generatePath(MARKETING_ORG_PATH, params),

  ANALYTICS_PATH,
  generateAnalyticsPath: <TParams extends ContractDrugAnalyticsPathParams>(params: TParams) =>
    generatePath(ANALYTICS_PATH, params),

  PLANOGRAM_TASKS_CONTROL,
  PLANOGRAM_TASK_CONTROL_DETAIL_PATH,
  generatePlanogramTaskControlDetailPath: (params: PlanogramTaskControlDetailPathParams) =>
    generatePath(PLANOGRAM_TASK_CONTROL_DETAIL_PATH, params),

  PHARMACISTS_CONTROL_PATH,
  generatePharmacistsControlDetailPath: (params: PharmacistsControlDetailPathParams) =>
    generatePath(PHARMACISTS_CONTROL_DETAIL_PATH, params),

  PHARMACISTS_CONTROL_DETAIL_PATH,

  PHARMACISTS_CONTROL_DETAIL_RECEIPTS_PATH,
  generatePharmacistsControlDetailReceiptsPath: (params: PharmacistsControlDetailPathParams) =>
    generatePath(PHARMACISTS_CONTROL_DETAIL_RECEIPTS_PATH, params),

  PHARMACISTS_CONTROL_DETAIL_WALLET_PATH,
  generatePharmacistsControlDetailWalletPath: (params: PharmacistsControlDetailPathParams) =>
    generatePath(PHARMACISTS_CONTROL_DETAIL_WALLET_PATH, params),

  PHARMACISTS_CONTROL_DETAIL_RECEIPT_INFO_PATH,
  generatePharmacistsControlDetailReceiptInfoPath: (params: PharmacistsControlDetailReceiptInfoPathParams) =>
    generatePath(PHARMACISTS_CONTROL_DETAIL_RECEIPT_INFO_PATH, params),
} as const;
