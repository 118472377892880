import { Stack, Typography, Tooltip, styled } from '@mui/material';

import { formatDrugName, parseCity } from '@/helpers/format.helpers';
import { ReactComponent as InfoSVG } from '@/assets/icons-2.0/info.svg';
import { CustomTypography } from '@/components/CustomTypography';
import { DorimPromoDrug } from '@/api/domains/promo-contracts.types';

type DistributorsTooltipProps = {
  distributors: NonNullable<DorimPromoDrug['distributors']>;
};

const DistributorsTooltip = (props: DistributorsTooltipProps) => {
  const { distributors } = props;

  return (
    <>
      <Typography variant="Body/small" color="neutral.500">
        Дистрибьюторы
      </Typography>
      {distributors.map(distributor => (
        <Typography key={distributor.id} component="p" variant="Body/small">
          <Typography component="span" variant="inherit" color="neutral.100">
            {distributor.name}
          </Typography>
          {distributor.address?.city && (
            <Typography component="span" variant="inherit" color="neutral.500">
              {` (${parseCity(distributor.address.city)})`}
            </Typography>
          )}
        </Typography>
      ))}
    </>
  );
};

type ContractDrugInfoProps = {
  name: string;
  number: number;
  form: string;
  distributors?: NonNullable<DorimPromoDrug['distributors']>;
};

const StyledInfoSVG = styled(InfoSVG)(({ theme }) => ({
  color: theme.palette.custom.blue,
}));

export const ContractDrugInfo = (props: ContractDrugInfoProps) => {
  const { name, number, form, distributors } = props;

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {distributors && (
        <Tooltip
          arrow
          placement="right"
          title={<DistributorsTooltip distributors={distributors} />}
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -5],
                  },
                },
              ],
            },
          }}
        >
          <span style={{ display: 'inherit' }}>
            <StyledInfoSVG />
          </span>
        </Tooltip>
      )}

      <CustomTypography noWrap lineClamp={2} variant="Body/Bold/large" flexWrap="wrap">
        {formatDrugName(name, form, number)}
      </CustomTypography>
    </Stack>
  );
};
