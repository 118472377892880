import { Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

type StyledRootProps = {
  hasFooter?: boolean;
  hasTabbar?: boolean;
  hasPadding?: boolean;
  hasSidebar?: boolean;
};

type ModalBodySlots =
  | {
      hasFooter?: never;
      sidebar?: React.ReactNode;
    }
  | {
      hasFooter?: true;
      sidebar?: never;
    };

export type ModalBodyProps = ModalBodySlots &
  Omit<StyledRootProps, 'hasFooter' | 'hasSidebar'> &
  React.PropsWithChildren<{
    ContentProps?: Omit<StackProps, 'className'>;
  }>;

// ----------------------------------------------------------------------

const StyledRoot = styled('div', {
  shouldForwardProp: propName =>
    propName !== 'hasSidebar' && propName !== 'hasFooter' && propName !== 'hasTabbar' && propName !== 'hasPadding',
})<StyledRootProps>(
  {
    flexGrow: 1,
    display: 'flex',
    overflow: 'hidden',

    '.ModalBody-content': {
      height: '100%',
      flexGrow: 1,
      overflow: 'hidden',
    },
  },
  ({
    hasTabbar,
    hasFooter,
    theme: {
      dimensions: { modal },
    },
  }) => {
    if (hasTabbar && hasFooter) {
      return {
        height: `calc(100vh - 64px - ${modal.header.height}px - ${modal.tabbar.height}px - ${modal.footer.height}px)`,
        // eslint-disable-next-line max-len
        maxHeight: `calc(${modal.maxHeight}px - ${modal.header.height}px - ${modal.tabbar.height}px - ${modal.footer.height}px)`,
      };
    }

    if (hasTabbar) {
      return {
        height: `calc(100vh - 64px - ${modal.header.height}px - ${modal.tabbar.height}px)`,
        maxHeight: `calc(${modal.maxHeight}px - ${modal.header.height}px - ${modal.tabbar.height}px)`,
      };
    }

    if (hasFooter) {
      return {
        height: `calc(100vh - 64px - ${modal.header.height}px - ${modal.footer.height}px)`,
        maxHeight: `calc(${modal.maxHeight}px - ${modal.header.height}px - ${modal.footer.height}px)`,
      };
    }

    return {
      height: `calc(100vh - 64px - ${modal.header.height}px)`,
      maxHeight: `calc(${modal.maxHeight}px - ${modal.header.height}px)`,
    };
  },
  ({ hasPadding, theme }) =>
    hasPadding && {
      '.ModalBody-content': {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.neutral,
      },
    },
  ({ hasSidebar, theme }) =>
    hasSidebar && {
      '.ModalBody-content': {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
  ({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      '@media (orientation: landscape)': {
        flexDirection: 'column',
        height: 'auto',
      },
      flexDirection: 'column',
      height: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      '@media (orientation: landscape)': {
        flexDirection: 'column',
        height: 'auto',
      },
      flexDirection: 'column',
      height: 'auto',
    },
  }),
);

/**
 * @deprecated Use `import { ModalBody } from '@/components/Modal/ModalBody2'` instead
 */
export const ModalBody = (props: ModalBodyProps) => {
  const { hasFooter, hasTabbar, hasPadding, children, sidebar, ContentProps } = props;

  const hasSidebar = !!sidebar;

  return (
    <StyledRoot hasTabbar={hasTabbar} hasSidebar={hasSidebar} hasFooter={hasFooter} hasPadding={hasPadding}>
      <Stack {...ContentProps} className="ModalBody-content">
        {children}
      </Stack>
      {sidebar}
    </StyledRoot>
  );
};
