import { useParams } from 'react-router';
import { Stack, Typography } from '@mui/material';

import { ContractMarketingOrgPathParams } from '@/router/paths/dorim-promo.path';

import { DorimPromoDrugChart, type DorimPromoDrugChartProps } from './DorimPromoDrugChart';
import { useSumPlanState } from './useSumPlanState';

type DorimPromoBonusChartProps = DorimPromoDrugChartProps & {
  chartRef?: React.Ref<HTMLSpanElement>;
};

export const DorimPromoBonusChart = (props: DorimPromoBonusChartProps) => {
  const { chartRef, ...restSliderProps } = props;

  const { contractId, marketingOrgId } = useParams<ContractMarketingOrgPathParams>();
  const sumPlanState = useSumPlanState({
    agreementId: contractId ? Number(contractId) : undefined,
    marketingOrgId: marketingOrgId ? Number(marketingOrgId) : undefined,
  });

  return (
    <Stack direction="row" spacing={3} width="100%" alignItems="flex-end">
      <Typography variant="Body/small" color="neutral.700" textAlign="right" flexShrink={0}>
        План, шт
        <br />
        Бонус, %
      </Typography>
      <DorimPromoDrugChart ref={chartRef} sumPlanState={sumPlanState} {...restSliderProps} />
    </Stack>
  );
};
