import { SvgIcon } from '@mui/material';
import { size, values } from 'lodash';

import { ActionIconButton, MainActionButton } from '@/components/buttons';
import { useReceiptInfoStoreSelectedRows } from '../receipt-info.store';
import { ReactComponent as CheckIcon } from '@/assets/icons-2.0/playlist_add_check.svg';
import { ReceiptInfoDataGridRow } from '../types';
import { useCheckReceiptBinding } from './useCheckReceiptBinding';
import { useGlobalErrorStore } from '@/components/dialogs';

export const CheckReceiptMainButton = () => {
  const selectedReceipts = useReceiptInfoStoreSelectedRows();
  const { setGlobalError } = useGlobalErrorStore();
  const { mutate: checkReceipt } = useCheckReceiptBinding();
  const isReceiptsNotSelected = size(selectedReceipts) < 1;

  const handleClick = () => {
    try {
      if (isReceiptsNotSelected) return;
      const receiptItemsIds = values(selectedReceipts).map(receipt => receipt.id);

      checkReceipt({ error_type_ids: [], receipt_item_ids: receiptItemsIds });
    } catch (error) {
      setGlobalError(error);
    }
  };

  return (
    <MainActionButton
      disabled={size(selectedReceipts) < 1}
      onClick={handleClick}
      startIcon={<SvgIcon component={CheckIcon} />}
    >
      Проверить
    </MainActionButton>
  );
};

export type ReceiptInfoInlineCheckButtonProps = {
  selectedItem?: ReceiptInfoDataGridRow;
  onClick?: (event: React.MouseEvent) => void;
};

export const ReceiptInfoInlineCheckButton = (props: ReceiptInfoInlineCheckButtonProps) => {
  const { selectedItem, onClick, ...restProps } = props;
  const { setGlobalError } = useGlobalErrorStore();
  const { mutate: checkReceipt } = useCheckReceiptBinding();

  const handleClick = (event: React.MouseEvent) => {
    onClick?.(event);
    try {
      const receiptId = selectedItem?.id;
      if (!receiptId) return;

      checkReceipt({ error_type_ids: [], receipt_id: receiptId });
    } catch (error) {
      setGlobalError(error);
    }
  };

  return (
    <ActionIconButton onClick={handleClick} {...restProps} data-testid="receipt-info-check-inline-btn">
      <SvgIcon component={CheckIcon} />
    </ActionIconButton>
  );
};
