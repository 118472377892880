import { forwardRef } from 'react';
import { SlotComponentProps } from '@mui/base';
import { SliderComponentsPropsOverrides, SliderOwnerState, SliderThumb, Tooltip, Typography } from '@mui/material';
import { first, last } from 'lodash';

import type { Plan, SumPlanState } from '@/api/domains/promo-contracts.types';
import { Slider, type SliderProps } from '@/components/Slider';

import { DorimPromoChartLabel } from './DorimPromoChartLabel';
import { DorimPromoTooltip } from './DorimPromoTooltip';
import { getActivePlan, useSliderColor } from './helpers';

export type DorimPromoDrugChartProps = Omit<SliderProps, 'currentValue' | 'marks' | 'values'> & {
  orderedCount: number;
  plans: Plan[];
  sumPlanState?: SumPlanState;
};

type SliderThumbProps = SlotComponentProps<'span', SliderComponentsPropsOverrides, SliderOwnerState>;
type ThumbProps = SliderThumbProps & {
  currentPlan?: Plan;
  orderedCount: number;
};

const Thumb = (props: ThumbProps) => {
  const { orderedCount, currentPlan, ...sliderProps } = props;

  let currentCashbackAmount = 0;
  if (currentPlan?.cashback && currentPlan?.count) {
    currentCashbackAmount = (currentPlan.cashback / currentPlan.count) * orderedCount;
  }

  return (
    <Tooltip
      disableInteractive
      title={<DorimPromoTooltip isCurrentCashback cashbackAmount={currentCashbackAmount} />}
      placement="bottom"
      arrow
    >
      <SliderThumb {...sliderProps} />
    </Tooltip>
  );
};

const getCurrentPlan = (
  orderedCount: number,
  plans: DorimPromoDrugChartProps['plans'],
  sumPlanState?: SumPlanState,
) => {
  if (sumPlanState === 'underMinPlan') {
    return first(plans);
  } else if (sumPlanState === 'maxPlanCompletion') {
    return last(plans);
  }

  return getActivePlan(orderedCount, plans);
};

export const DorimPromoDrugChart = forwardRef<HTMLSpanElement, DorimPromoDrugChartProps>((props, ref) => {
  const { plans, orderedCount, sumPlanState, ...sliderProps } = props;

  const currentPlan = getCurrentPlan(orderedCount, plans, sumPlanState);
  const values = plans.map(plan => plan.count);
  const currentSliderColor = useSliderColor({ currentValue: orderedCount, values });
  const isPlanExceeded = values.length > 0 && orderedCount > values[values.length - 1];

  return (
    <Slider
      ref={ref}
      values={values}
      currentValue={orderedCount}
      sliderColor={currentSliderColor}
      isShowExceedingValue
      slots={{
        thumb: props => <Thumb {...props} orderedCount={orderedCount} currentPlan={currentPlan} />,
      }}
      marks={plans.map((plan, index) => ({
        value: index,
        label: (
          <DorimPromoChartLabel
            isFirst={index === 0}
            isPlanExceeded={isPlanExceeded && index === plans.length - 1}
            tooltipTitle={
              <DorimPromoTooltip cashbackAmount={plan.cashback} isCurrentCashback={orderedCount >= plan.count} />
            }
          >
            <Typography component="span" variant="inherit">
              {plan.count}
            </Typography>
            <Typography component="span" variant="inherit">
              {plan.bonus}
            </Typography>
          </DorimPromoChartLabel>
        ),
      }))}
      {...sliderProps}
    />
  );
});
