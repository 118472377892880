import type { LoadingButtonTypeMap } from '@mui/lab';
import { styled } from '@mui/material';
import type { OverridableComponent, OverrideProps } from '@mui/material/OverridableComponent';

import { RoundedButton } from '../buttons';

export type DocumentToolbarButtonProps<
  RootComponent extends React.ElementType = LoadingButtonTypeMap['defaultComponent'],
> = OverrideProps<LoadingButtonTypeMap<{}, RootComponent>, RootComponent> & {
  component?: React.ElementType;
  'data-testid'?: string;
};

const StyledRoundedButton = styled(RoundedButton)({
  minWidth: 120,
}) as typeof RoundedButton;

export const DocumentToolbarButton: OverridableComponent<LoadingButtonTypeMap> = (
  props: DocumentToolbarButtonProps,
) => <StyledRoundedButton variant="outlined" color="inherit" size="medium" {...props} />;
